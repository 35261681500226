import { ContactUsVLair, ContactUsVLairMinimal } from "../components/ContactUs";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";

export default function AboutPage() {
  return (
    <ValsPage pagename="vlair" commentsEnabled={false} contactUsEnabled={false}>
      <tr className="border-b-[1px] pb-4">
        <p className="font-serif text-xl md:text-3xl text-black font-semibold pt-20 pb-4">
          Vals Legal AI Report
        </p>
      </tr>

      <div className="space-y-10 pt-16 pb-8">
        <Section id="overview" title="Overview">
          <div className="space-y-4">
            <p>
              Vals AI, supported by Legaltech Hub, is collaborating with a
              number of top US law firms, including Reed Smith and Fisher
              Phillips, AI vendors, and ALSP, Cognia, to produce a new legal AI
              benchmarking study that will evaluate the accuracy and efficacy of
              the legal industry's most used generative AI platforms.
            </p>

            <p>
              The new study will focus on specific legal tasks across
              transactional, disputes, and advisory disciplines, including
              document-related tasks, case analysis, and legal and market
              research. For each task, the participant law firms will provide a
              curated set of non-confidential or publicly sourced legal
              documents and corresponding questions with model answers.
              Responses to the questions will be submitted from each of the AI
              vendors' platforms as well as a control group of independent
              lawyers provided by Cognia, in order to establish a lawyer
              performance baseline.
            </p>

            <p>
              Vals AI will use its proprietary auto-evaluation framework
              platform to produce a blind assessment of the submitted responses
              against the model answers. The results of the study will be
              published later in 2024.
            </p>

            <p>
              This marks the first time that multiple law firms and vendors will
              have come together to objectively assess legal AI platform
              performance on real world examples of legal tasks.
            </p>
          </div>
        </Section>
        <ContactUsVLairMinimal />
        <Section id="background" title="Background">
          <div className="space-y-4">
            <p>
              A number of legal AI benchmarking reports have been published over
              the past year, with a variety of outcomes that show AI performing
              both above and below human baselines and that for certain legal
              tasks, particularly legal research, there remain high rates of
              error. These reports raise questions about the utility of
              generative AI in professional services, leaving lawyers, law firms
              and other legal service providers confused and unsure.
            </p>
            <p>
              As a result, multiple industry groups and legal practitioners
              globally have made calls for independent benchmarking of legal AI
              platforms in order to instill a greater understanding of and trust
              in generative AI for legal work. This study begins to answer those
              calls.
            </p>
          </div>
        </Section>
        <Section id="participant-vendors" title="Participant Vendors">
          <div className="space-y-4">
            <p>
              The study will involve benchmarking legal AI solutions available
              from the following vendors:
            </p>
            <div className="flex flex-col items-center justify-center md:space-y-4 pt-8">
              <div className="flex md:flex-row flex-col items-center justify-center space-x-4 md:space-y-0 space-y-4">
                <a
                  href="https://www.harvey.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/vlair_participants/harvey.png"
                    alt="Harvey"
                    className="h-12 w-auto"
                  />
                </a>
                <a
                  href="https://www.lexisnexis.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/vlair_participants/lexis.png"
                    alt="LexisNexis"
                    className="h-12 w-auto"
                  />
                </a>
                <a
                  href="https://www.thomsonreuters.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/vlair_participants/thomsonreuters.png"
                    alt="Thomson Reuters"
                    className="h-12 w-auto"
                  />
                </a>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center space-x-4 md:space-y-0 md:pt-0 pt-4 space-y-4">
                <a
                  href="https://vlex.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/vlair_participants/vlex.png"
                    alt="VLex"
                    className="h-12 w-auto"
                  />
                </a>
                <a
                  href="https://www.vecflow.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/vlair_participants/vecflow.png"
                    alt="VecFlow"
                    className="h-12 w-auto"
                  />
                </a>
              </div>
            </div>
          </div>
        </Section>
        <Section id="methodology" title="Methodology">
          <div className="space-y-4">
            <p>
              The questions and data will be sourced from the participating law
              firms, who will provide both test questions and "ground truth"
              correct answers. The questions provided will be sent to the APIs /
              platforms of the vendors to collect the test samples. The
              platforms are tested "end-to-end" - meaning we do not evaluate or
              look at any intermediate step, only the final user-facing output.
            </p>

            <p>
              In addition, lawyers source by Cognia will perform each of the
              tasks without the support of any legal AI solutions.
            </p>

            <p>
              The Vals AI auto evaluation framework platform will then be used
              to compare the AI and lawyer test outputs to the ground-truth
              answers, based on a scoring rubric considering the accuracy,
              usefulness, citations (where relevant), style and format of the
              responses. To ensure the accuracy of the Auto Eval platform
              itself, any test that fails the auto evaluation will be manually
              reviewed by a group of independent law librarians to prevent any
              false negatives.
            </p>

            <p>
              If a given vendor does not include feature support for a given
              skill, they will not be included in or graded on that section of
              the report.
            </p>
          </div>
        </Section>
        <Section id="timing" title="Timing">
          <div className="space-y-4">
            <p>
              The study will be conducted over October and November 2024, with
              the final report targeted to be issued towards end November or
              early December.
            </p>
          </div>
        </Section>
        {/* <Section id="contact" title="Contact">
          <div className="space-y-4">
            <p>
              For any questions, reach out to rayan@vals.ai, langston@vals.ai,
              tara@vals.ai, jeroen@legaltechnologyhub.com, and
              nikki@legaltechnologyhub.com.
            </p>
          </div>
        </Section> */}
      </div>
      <ContactUsVLair />
    </ValsPage>
  );
}
