import { useEffect, useRef, useState } from "react";

type TableOfContentsProps = {};

function getNestedHeadings(headingElements: any[]) {
  const nestedHeadings: any[] = [];

  headingElements.forEach((heading: any, index: number) => {
    const { innerText: title, id } = heading;
    if (heading.nodeName === "H1") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H2" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
}

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState<any[]>([]);

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h1, h2"));

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const Headings = ({
  headings,
  activeId,
}: {
  headings: any[];
  activeId: number | undefined;
}) => (
  <ul>
    {headings.map((heading) => (
      <li
        key={heading.id}
        className={
          "table-of-contents-li" + (heading.id === activeId ? " active" : "")
        }
      >
        <a
          className="table-of-contents-a"
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector(`#${heading.id}`)!.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {heading.title}
        </a>
        {/* Logic for subheadings, which we currently don't use */}
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child: any) => (
              <li
                key={child.id}
                className={
                  "table-of-contents-li" +
                  (child.id === activeId ? " active" : "")
                }
              >
                <a
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`)!.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className="table-of-contents-a"
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

const useIntersectionObserver = (setActiveId: any) => {
  const headingElementsRef = useRef<any>({});
  useEffect(() => {
    const callback = (headings: any[]) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings: any[] = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id: any) =>
        headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort((a, b) => {
          return getIndexFromId(a.target.id) - getIndexFromId(b.target.id);
        });
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-80px 0px 0px 0px", // "0px 0px -40% 0px",
    });

    const headingElements = Array.from(document.querySelectorAll("h1, h2"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

export default function TableOfContents(props: TableOfContentsProps) {
  const [activeId, setActiveId] = useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <div className="flex justify-center items-center h-screen fixed lg:left-8">
      <nav
        className="overflow-auto hidden md-lg:block"
        style={{ maxHeight: "calc(100vh - 40px)" }}
      >
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    </div>
  );
}
