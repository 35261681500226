import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

export function fadeInUp(
  elem: JSX.Element,
  // key = undefined,
  delay: number = 0,
  offset = 200
): JSX.Element {
  return (
    <AnimationOnScroll
      animateIn="animate__fadeInUp"
      delay={delay}
      offset={offset}
      animateOnce={true}
    >
      {elem}
    </AnimationOnScroll>
  );
}

export function fadeIn(
  elem: JSX.Element,
  // key = undefined,
  delay: number = 0,
  offset: number = 200
): JSX.Element {
  return (
    <AnimationOnScroll
      animateIn="animate__fadeIn"
      delay={delay}
      offset={offset}
      animateOnce={true}
    >
      {elem}
    </AnimationOnScroll>
  );
}
