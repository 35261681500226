import { Snackbar, SnackbarContent } from "@mui/material";
import { useState } from "react";
import { fadeInUp } from "../util/animations";
import MailingList, { SnackbarStateType } from "./MailingList";

export default function MailingListWrapper({
  usingAnimation = true,
}: {
  usingAnimation?: boolean;
}) {
  const [snackbarState, setSnackbarState] = useState<SnackbarStateType>({
    open: false,
    success: true,
    message: "",
  });

  return (
    <div className="flex justify-center">
      {/* Needs to be outside of the MailingList component because of animation */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarState.open}
        onClose={() => setSnackbarState({ ...snackbarState, open: false })}
        autoHideDuration={5000}
      >
        <SnackbarContent
          message={snackbarState.message}
          sx={{
            backgroundColor: snackbarState.success ? "black" : "#730303",
          }}
        />
      </Snackbar>
      {usingAnimation &&
        fadeInUp(
          <MailingList
            snackbarState={snackbarState}
            setSnackbarState={setSnackbarState}
          />
        )}
      {!usingAnimation && (
        <MailingList
          snackbarState={snackbarState}
          setSnackbarState={setSnackbarState}
        />
      )}
    </div>
  );
}
