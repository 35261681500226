export default function VLairBanner() {
  return (
    <a href="/vlair">
      <div className="w-full bg-[#0F172A] text-[#F1F5F9] text-center py-4 ">
        <p className="text-lg">
          View the new <strong>Vals Legal AI Report</strong> announcement here!
        </p>
      </div>
    </a>
  );
}
