import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ColorBlock from "./ColorBlock";

type ModelOutputExampleProps = {
  names: string[];
  children: any[];
};

// Will need to add more if more than 3 examples
const COLORS = ["rose", "green", "blue", "violet", "orange"];

export default function ModelOutputExamples(props: ModelOutputExampleProps) {
  const [activeTabIndex, setActiveTab] = useState(0);
  const tabStyle = useState(
    "w-[175px] h-[50px] md:h-[50px] bg-white border-2 md:border-4 border-black flex justify-center items-center mt-2 " +
      (props.names.length > 4 ? " w-[140px] " : "")
  );
  const isDesktop = useMediaQuery({ minWidth: 600 });
  const styling = isDesktop
    ? "flex justify-around py-4"
    : "w-full grid grid-cols-2 gap-4 items-center py-4";

  return (
    <div>
      <div className={styling}>
        {props.names.map((value, index) => {
          return (
            <button
              className={`${tabStyle} ${
                activeTabIndex === index ? "active" : ""
              }`}
              onClick={() => setActiveTab(index)}
            >
              {value as any}
            </button>
          );
        })}
      </div>
      {/* Tab content */}

      <ColorBlock color={COLORS[activeTabIndex % COLORS.length] as any}>
        {props.children[activeTabIndex]}
      </ColorBlock>
    </div>
  );
}
