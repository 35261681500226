import { DiscussionEmbed } from "disqus-react";
import { ENV } from "../util/constants";

function getDisqusShortname() {
  if (ENV == "PROD") {
    return "vals-ai";
  } else {
    return "dev-vals-ai";
  }
}

type DisqusComponentProps = {
  pagename: string;
};

export default function DisqusComponent(props: DisqusComponentProps) {
  return (
    <div className="mt-48 md:w-[800px] w-[100%]">
      <DiscussionEmbed
        shortname={getDisqusShortname()}
        config={{
          identifier: props.pagename,
          title: "Vals.AI",
          language: "en",
        }}
      />
    </div>
  );
}
