import { ReactNode } from "react";
import { fadeInUp } from "../util/animations";

type PageTitleProps = {
  title: string;
  subtitle: string | ReactNode;
};

export default function PageTitle(props: PageTitleProps) {
  return (
    <>
      <tr className="border-b-[1px] pb-4">
        <p className="font-serif text-xl md:text-3xl text-black font-semibold pt-20 pb-4">
          {props.title}
        </p>
      </tr>
      {fadeInUp(
        <p className="font-serif text-sm md:text-xl text-black italic text-center pt-6">
          {props.subtitle}
        </p>,
        0,
        0
      )}{" "}
    </>
  );
}
