import XIcon from "@mui/icons-material/X";
import { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";
import ContactUs from "./ContactUs";
import DisqusComponent from "./DisqusComponent";
import MailingListWrapper from "./MailingListWrapper";
import Navbar from "./Navbar";
import TableOfContents from "./TableOfContents";
import VLairBanner from "./VLairBanner";

interface ValsPageProps {
  pagename: string;
  children: ReactElement | ReactElement[] | string | number;
  commentsEnabled?: boolean;
  tableOfContentsEnabled?: boolean;
  showNames?: boolean;
  contactUsEnabled?: boolean;
}

const MobileFooter = () => {
  return (
    <div className="pt-16 pb-8">
      <MailingListWrapper usingAnimation={false} />
      <div className="flex justify-around pt-3 space-x-4">
        <p className="opacity-60"> © 2024 Vals AI. All Rights Reserved.</p>

        <a
          href={`https://twitter.com/_valsai`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <XIcon className="opacity-60" />
        </a>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="flex justify-between md:pt-32 pt-16 pb-8 w-[800px]">
      <div className="flex pt-3 space-x-4">
        <p className="opacity-60"> © 2024 Vals AI. All Rights Reserved.</p>

        <a
          href={`https://twitter.com/_valsai`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <XIcon className="opacity-60" />
        </a>
      </div>
      <MailingListWrapper usingAnimation={false} />
    </div>
  );
};

export default function ValsPage({
  pagename,
  children,
  // We need to unpack them to use default props.
  tableOfContentsEnabled = true,
  commentsEnabled = true,
  showNames = false,
  contactUsEnabled = true,
}: ValsPageProps) {
  const isDesktop = useMediaQuery({ minWidth: 600 });

  return (
    <div className="bg-background">
      <VLairBanner />

      {tableOfContentsEnabled && <TableOfContents />}
      <Navbar />
      <div className="px-2 md:px-64 bg-background ">
        <div className="flex flex-col items-center">
          {children}
          {contactUsEnabled && <ContactUs />}
          {commentsEnabled && <DisqusComponent pagename={pagename} />}
          {isDesktop ? <Footer /> : <MobileFooter />}
          {showNames && (
            <p className="pt-16 pb-12 opacity-50 font-serif">
              Created by{" "}
              <a
                href="https://www.linkedin.com/in/rayankrishnan/"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rayan Krishnan
              </a>
              ,{" "}
              <a
                href="https://www.linkedin.com/in/langston-nashold/"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Langston Nashold
              </a>
              , and the Vals AI team.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
