import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import legalBenchData from "../data/results/legalbench_results.json";
import "../styles/basics.css";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          By 0.9 percentage points, OpenAI's o1 preview has found the lead on
          LegalBench. It does especially well on Rule application tasks - most
          likely because of its enhanced reasoning capabilities.
        </li>
        <li>
          Llama 3.1 Instruct (405B & 70B) is the second-best model -- beating
          out both the other closed-source and open-source models. It is also
          more expensive than other open-source models for inference, but still
          very competitive on price compared to the closed models.
        </li>

        <li>
          The upgraded Claude 3.5 Sonnet and GPT-4o are exactly tied for third
          and fourth place. They are also priced very similarly.
        </li>
        <li>
          GPT-4o Mini stands out as a great budget model, achieving strong
          performance while being one of the cheapest models available.
        </li>
        <li>
          A given model's performance can vary dramatically across different
          legal tasks. There is still significant room for improvement for these
          models to perform well on legal tasks.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"o1 preview"}
            icon={"logos/oai.png"}
            color={"accentgreen"}
            acc={81.4}
            costIn={15}
            costOut={60}
            latency={10.32}
            desc={
              "o1 preview is the new state-of-the-art, performing especially well on Rule Tasks.; " +
              "It is very expensive - much more than 4o or Claude 3.5 Sonnet. It also comes at a high latency cost.; " +
              "It's also harder to prompt - it lacks the fine-grained controls we have collectively become accustomed to.; " +
              "o1 was very verbose on the non-multiple-choice tasks (see the model examples)."
            }
          />,
          75,
          75
        )}
        {fadeIn(
          <ModelCard
            name={"Llama 3.1 (405B)"}
            icon={"logos/meta.png"}
            color={"accentblue"}
            acc={80.5}
            costIn={5}
            costOut={5}
            latency={2.0}
            desc={
              "The latest Llama-3.1 70B model had a standout performance on LegalBench, and was previously state-of-the-art before being dethroned by O1.; " +
              "The model is priced higher than other open-source models, at $5 / MTok for both input and output. This is still in-line with GPT-4o and 3.5 Sonnet though.; " +
              "We will see how the closed-source models respond to this new entry into the foundation model market."
            }
          />,
          100
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          There has been a considerable effort to measure language model
          performance in academic tasks and chatbot settings but these
          high-level benchmarks are not applicable to specific industry use
          cases. Here we start to remedy this by reporting our
          application-specific findings and live leaderboard results on
          LegalBench, a large crowd-sourced collection of legal reasoning tasks.
          The data set is quite comprehensive, covering six major categories.
        </p>
        <ColorBlock color="violet">
          <ol className="text-section">
            <li>
              Issue-spotting, where a model must determine if a fact has
              relevance to a particular law or legal entity.
            </li>
            <li>
              Rule-recall, where a model must identify a relevant rule or state
              its characteristics.
            </li>
            <li>
              Rule-conclusion, where a model must predict a legal outcome.
            </li>
            <li>
              Rule-application, where a model must analyze how a rule was
              applied to reach a conclusion.
            </li>
            <li>
              Interpretation, where a model must parse and understand legal
              text.
            </li>
            <li>
              Rhetorical understanding, where a model must determine whether a
              legal argument performs a certain function.
            </li>
          </ol>
        </ColorBlock>
        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per task category are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/legalbench/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/legalbench/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          On a task-by-task basis, o1 preview was often the best - but 4o and
          Llama 3.1 also claimed two of the top task-specific spots. We can also
          see that a lot of the bump in performance for o1 came from tasks in
          the "Rule" category - for example, it got a nearly perfect 98% on
          "Rule QA", one of the few free response tasks in LegalBench.
        </p>
        <br />
        <p className="text-section">
          Gemini Pro, Claude Sonnet, and Cohere Command R+ competed for the
          middle spots on the leaderboard. The open source models also generally
          had average performance, although amongst them, the Llama models were
          significantly better. Interestingly, the Llama models did not perform
          well on Rule tasks, and 3.5 Sonnet upgraded did not perform well on
          issue tasks.
        </p>
        <br />

        {isDesktop && (
          <iframe
            src="plots/legalbench/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/legalbench/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The cost-accuracy graph shows a few models that define a Pareto curve
          of tradeoffs: GPT-4o mini, Llama 3.1 70B, Llama 3.1 405B, and o1
          preview. Shortly off the Pareto curve are GPT-4o and Claude 3.5
          Sonnet. Among these four models, the objective difference in
          performance is small (a matter of a few percentage points), however,
          they have wildly different prices. GPT-4o Mini particularly stands out
          as having a very high quality to price ratio. A cheaper model may
          still be a better choice in domains with high token usage or cost
          sensitivity.
        </p>
        <br />

        <p className="text-section">
          Amongst the other models, there is a somewhat logarithmic trend, with
          the more expensive models seeing diminishing returns for marginal
          cost. Also, models of previous "generations" (Claude 2, GPT 3.5,
          etc.), perform strictly worse for their price than the newer models --
          likely, providers are disincentivizing their use.
        </p>
        <br />

        <p className="text-section">
          Gemini 1.5 does not perform significantly better than Gemini 1.0 --
          although its performance is better on certain tasks and categories, it
          performs significantly worse on others. It often is overly verbose, or
          does not understand the in-context examples without additional
          prompting.
        </p>
        <br />
      </>
    </Section>
  );
};

const NotableMentions = () => {
  return (
    <Section title="Notable Mentions" id="notable-mentions">
      <div className="space-y-8">
        {fadeIn(
          <ModelCard
            name={"Claude 3.5 Sonnet"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={78.7}
            costIn={3}
            costOut={15}
            latency={0.83}
            desc={
              "Claude 3.5 Sonnet (Upgraded) was the tied for third place on Legalbench; " +
              "It performed especially well on rule tasks, albeit poorly on issue tasks.; " +
              "It performs better than Opus, the previously best Antrhopic model on this task, at a significant price reduction."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Quirks = () => {
  return (
    <Section title="Additional Notes" id="quirks">
      <div className="quirks">
        <ColorBlock color="green">
          <p className="text-section">
            <strong>Gemini: </strong> The Gemini documentation and error
            handling were extremely poor. Even after turning content moderation
            to the least restrictive setting, the API frequently returned an
            “unsafe content” error. In some cases, we could circumvent this
            because the error payload included the supposedly unsafe generation
          </p>

          <p className="text-section">
            Often, we’d get an index out-of-bounds error originating from within
            Gemini’s own Python SDK, instead of a more meaningful error message.
            We debugged further, now believing this to be an additional level of
            content moderation not exposed to the user in any capacity.
          </p>

          <p className="text-section">
            In general, Gemini has much stricter content moderation on its
            output than most other models. This is true even if Content Blocking
            was set to the lowest possible settings. Because of this, the model
            was not able to successfully produce outputs for many tests in the
            learned_hands tasks and others. These cases are treated as failures.
            The Gemini Pro model results may improve considerably if the content
            moderation is better calibrated.
          </p>

          <p className="text-section">
            Gemini Pro pricing is per character, not per token. We go by the
            pricing{" "}
            <a href="https://ai.google.dev/pricing" className="underline">
              listed here
            </a>
            , and assume an average of 4 characters per token.
          </p>
        </ColorBlock>

        <ColorBlock color="violet">
          <p className="text-section">
            <strong>Falcon: </strong>Falcon has a lower context window (2048
            tokens) than all of the other models tested. Because of this, we had
            to remove some of the in-context examples for Falcon for eight
            tasks. The longest in-context examples were removed first, and
            examples were removed from each class such that the classes remained
            balanced. These models were recently deprecated by Together AI and
            will be excluded from our evaluations in the future.
          </p>

          <p className="text-section">
            <strong>Alpaca: </strong>We found that Alpaca performed much better
            on tasks when we used the prompt template `{"{PROMPT}"}”`, rather
            than the recommended prompt of `
            {"### Instruction:\\n{PROMPT}\\n### Response:\\n"}`.
          </p>

          <p className="text-section">
            <strong>Muad Tasks: </strong>For these tasks, all models were liable
            to produce outputs such as “Option A”, instead of just “A”, “B”,
            etc. Therefore, we implemented an additional regex parser to remove
            the unnecessary “Option” token. This was done uniformly across
            tasks.
          </p>
        </ColorBlock>

        <ColorBlock color="rose">
          <p className="text-section">
            <strong>Claude 2: </strong>Almost all LegalBench tasks are
            multiple-choice, and expect a single word or choice as an output.
            Claude-2 has extreme difficulty producing outputs in this format.
            Even if explicit instructions are provided, such as “Don’t include
            an explanation for your choice”, “Answer in a single word only”,
            etc., the model reliably produced a paragraph-length output with
            some explanation.
          </p>

          <p className="text-section">
            To give the model a chance, we wrote a custom parser for Claude 2.
            We asked it to produce outputs in a JSON format with ‘explanation’
            and ‘answer’ keys, then extracted the ‘answer’ key. We did not
            perform this for any subsequent Claude models - Claude 3.0 and 3.5
            were evaluated normally.
          </p>

          <p className="text-section">
            When asked for a single-word response, the Claude 3 models still
            struggled to consistently follow these directions. These problems
            were solved once we provided a system prompt with these
            expectations.
          </p>
        </ColorBlock>
        <ColorBlock color="green">
          <p className="text-section">
            <strong>o1 preview: </strong>
            We generally include a system prompt instructing the model to only
            answer in one or two words or letters (since all tasks but rule_qa
            are multiple-choice). However, with GPT o1, we had to prepend what
            would have been the system prompt to the start of the prompt, as o1
            does not support system prompts yet.
          </p>
          <p className="text-section">
            GPT o1 did not let us configure the temperature used for the
            evaluation.
          </p>
          <p className="text-section">
            GPT o1 often would prepend outputs with "Label: " (for example,
            "Label: generic"). In the interest of having a reasonable basis of
            comparison, these were removed.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These experiments were run over the open{" "}
            <a
              href="https://hazyresearch.stanford.edu/legalbench/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              LegalBench dataset
            </a>{" "}
            which consists of 157 distinct legal tasks across 5 broad
            categories. Running these evaluations amounted to making ~80,000 API
            queries and submitting ~40M tokens per model. These tasks primarily
            evaluate LLMs in legal reasoning tasks found in academic settings.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs.
          </p>

          <p className="text-section">
            The majority of the tasks were evaluated based on the methodology
            used in LegalBench, which replicates the HELM “exact match”
            approach. We built upon this by adding additional regex checks that
            helped reduce false negatives caused by improper output formatting.
          </p>

          <p className="text-section">
            However, we additionally used our auto-evaluation platform to
            replace human review on one task, Rule QA. Reducing or eliminating
            human review costs allows for the creation of many additional
            open-form-response tasks, widening the range of possible future
            evaluations.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const Partners = () => {
  let outerStyling =
    "flex justify-center items-center border-[3px] border-[#D0C7BF] rounded-full w-[70px] h-[70px] md:w-[150px] md:h-[150px] md:border-[6px]";
  return (
    <div>
      <h1 className="header" id="partners-in-evaluation">
        Partners in Evaluation
      </h1>
      <div className="flex justify-around pt-2">
        {fadeIn(
          <a
            href="https://law.stanford.edu/codex-the-stanford-center-for-legal-informatics/"
            target="_blank"
          >
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/codex.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          0
        )}
        {fadeIn(
          <a href="https://lawbeta.github.io/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/lawbeta.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          200
        )}
        {fadeIn(
          <a href="https://www.together.ai/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/tai.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          400
        )}
        {/* <div className={outerStyling}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[40px]"
            />
          </div> */}
      </div>
    </div>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <p className="text-section">
        We show some examples from one of the LegalBench free response tasks
        (Rule QA) here. The models were asked "Where in the Federal Rules of
        Civil Procedure are notice requirements described?"
      </p>
      <br />

      <p className="text-section">
        This question is tricky because both Rules 4 and 5 talk about the
        service process and notice requirements. However, Rule 4 is the main
        rule that defines the first notice in a proceeding, whereas Rule 5 gives
        the procedures for filing pleadings, etc.
      </p>
      <br />

      <p className="text-section">
        This question is also difficult because implicit in the question is that
        it's "notice" of a suit. GPT correctly determines that the question is
        talking about the initial notice, whereas Opus does not. Interestingly,
        Gemini gets the answer wrong, but it is much more terse than the other
        two models. Despite performing best overall, Llama 3.1 makes the same
        mistake as Opus, citing Rule 5 instead of Rule 4. Interestingly, o1
        preview is a lot more verbose than other models for this question.
      </p>

      <br />
      <p className="text-section">
        It's important this is one question from a very large evaluation data
        set and is not representative of overall performance.
      </p>
      <br />
      <ColorBlock color="beige">
        <p className="text-section">
          Q: Where in the Federal Rules of Civil Procedure are notice
          requirements described? <br />
          A:
        </p>
      </ColorBlock>
      <ModelOutputExamples
        names={[
          "Opus",
          "o1 Preview",
          "GPT-4",
          "Gemini Pro 1.0",
          "Llama 3.1 405B",
        ]}
      >
        <>
          <p className="text-section">
            <strong>Opus: &nbsp;</strong>
            The notice requirements in federal civil litigation are primarily
            described in Rule 5 of the Federal Rules of Civil Procedure (FRCP).
            Specifically: 1. Rule 5(a) outlines the service requirements for
            pleadings and other papers, stating that unless the rules provide
            otherwise, each paper must be served on every party. 2. Rule 5(b)
            describes how service can be made, including the methods of service
            and the permissible means of electronic service. 3. Rule 5(c) states
            that all papers after the complaint required to be served must be
            filed with the court within a reasonable time after service. 4. Rule
            5(d) covers the filing requirements, including electronic filing and
            the filing of discovery materials. Additionally, other rules in the
            FRCP may contain specific notice requirements for certain
            situations, such as Rule 4 for serving a summons and complaint, Rule
            6 for computing and extending time, and Rule 55 for default
            judgments.
          </p>
        </>

        <>
          <p className="text-section">
            <strong>GPT o1 Preview</strong>
            <br />
            In the Federal Rules of Civil Procedure (FRCP), notice requirements
            are primarily outlined in several key rules that govern the
            procedures for initiating a lawsuit, serving documents, and
            notifying parties of actions taken in a case. The most relevant
            rules include:
            <br />
            Rule 4 – Summons:
            <br />
            Purpose: Establishes the procedures for serving a summons and
            complaint to initiate a civil action.
            <br />
            Key Provisions:
            <br />
            Rule 4(a): Specifies the contents of a summons, ensuring that it
            provides proper notice to the defendant about the lawsuit.
            <br />
            Rule 4(c)-(m): Details how service must be executed, by whom, and
            within what time frame, to ensure defendants are properly notified.
            <br />
            Rule 5 – Serving and Filing Pleadings and Other Papers:
            <br />
            Purpose: Governs the service of all documents filed after the
            initial complaint, such as pleadings, motions, and notices.
            <br />
            Key Provisions:
            <br />
            Rule 5(a): Requires that every pleading subsequent to the original
            complaint and certain other papers be served on every party.
            <br />
            Rule 5(b): Outlines permissible methods of service, including
            electronic means if consented to.
            <br />
            Rule 5(d): Addresses filing requirements after service, ensuring
            that the court records reflect all notices provided.
            <br />
            Rule 6 – Computing and Extending Time; Time for Motion Papers:
            <br />
            Purpose: Explains how to compute time periods specified in the
            rules, court orders, or statutes.
            <br />
            Key Provisions:
            <br />
            Rule 6(a): Provides the method for calculating deadlines, which is
            crucial for determining when notices must be served.
            <br />
            Rule 6(d): Adds time to certain deadlines when service is made by
            specific methods, acknowledging potential delays in notice receipt.
            <br />
            Rule 55 – Default; Default Judgment:
            <br />
            Purpose: Addresses procedures when a party fails to defend against a
            claim.
            <br />
            Key Provisions:
            <br />
            Rule 55(a): Allows for entry of default when a party fails to plead
            or otherwise defend.
            <br />
            Rule 55(b)(2): Requires that if the party against whom default
            judgment is sought has appeared personally or by a representative,
            they must be served with written notice of the application for
            judgment at least 7 days before a hearing.
            <br />
            Rule 65 – Injunctions and Restraining Orders:
            <br />
            Purpose: Governs the issuance of injunctions and temporary
            restraining orders.
            <br />
            Key Provisions:
            <br />
            Rule 65(a): Requires notice to the adverse party before granting a
            preliminary injunction.
            <br />
            Rule 65(b): Addresses requirements for issuing a temporary
            restraining order without notice, including specific findings that
            justify such action.
            <br />
            Rule 23 – Class Actions:
            <br />
            Purpose: Outlines procedures for class action lawsuits, including
            notifying class members.
            <br />
            Key Provisions:
            <br />
            Rule 23(c)(2): Mandates that for any class certified under Rule
            23(b)(3), the court must direct the best notice practicable under
            the circumstances to all members who can be identified through
            reasonable effort.
            <br />
            Rule 23(e): Requires notice to all class members of any proposed
            settlement, voluntary dismissal, or compromise.
            <br />
            Rule 77 – Conducting Business; Clerk's Authority; Notice of an Order
            or Judgment:
            <br />
            Purpose: Deals with the court clerk's duties in providing notice of
            court actions.
            <br />
            Key Provisions:
            <br />
            Rule 77(d): Obligates the clerk to serve notice of the entry of an
            order or judgment on each party, ensuring they are informed of court
            decisions.
            <br />
            Additional Rules with Notice Requirements:
            <br />
            Rule 14 – Third-Party Practice:
            <br />
            Allows a defending party to serve a summons and complaint on a
            nonparty who is or may be liable for all or part of the claim. The
            rule requires notice to the third-party defendant.
            <br />
            Rule 27 – Depositions to Perpetuate Testimony:
            <br />
            Establishes procedures for taking depositions before an action is
            filed. Requires that expected adverse parties be notified of the
            petition to perpetuate testimony.
            <br />
            Rule 37 – Failure to Make Disclosures or to Cooperate in Discovery;
            Sanctions:
            <br />
            Before certain sanctions are imposed, the offending party must be
            given notice and an opportunity to be heard.
            <br />
            Rule 45 – Subpoena:
            <br />
            Outlines the requirements for issuing and serving subpoenas,
            including providing notice to all parties before serving a subpoena
            for the production of documents and things or inspection of
            premises.
            <br />
            Summary:
            <br />
            The FRCP embeds notice requirements throughout its rules to ensure
            due process and fairness in civil litigation. The fundamental
            concept is that parties must be appropriately informed of actions
            that affect their rights so they can respond accordingly. While
            Rules 4 and 5 are central to the initial and ongoing service of
            documents, various other rules address specific situations where
            notice is required.
            <br />
            For a complete understanding of notice requirements, it's advisable
            to review these rules in detail and consider the context of the
            specific legal action. Additionally, local court rules and orders
            may impose further notice obligations beyond those in the FRCP.
            <br />
          </p>
        </>
        <>
          <p className="text-section">
            <strong>GPT 4: &nbsp;</strong>
            The notice requirements in the Federal Rules of Civil Procedure are
            primarily described in Rule 4, which deals with the summons. Rule 4
            outlines the procedures for issuing a summons, serving the summons
            and complaint, and what constitutes sufficient service of process.
            This rule is crucial for ensuring that a defendant is properly
            notified of the legal action against them, which is a fundamental
            aspect of due process. Additionally, other rules and sections within
            the Federal Rules of Civil Procedure also discuss notice
            requirements in various contexts, but Rule 4 is the primary rule
            concerning the initial notice to a defendant through the summons.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Gemini 1.0: &nbsp;</strong>
            Rule 4.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Llama-3.1 405B: &nbsp;</strong>
            A: The notice requirements are described in Rule 5 of the Federal
            Rules of Civil Procedure.
          </p>
        </>
      </ModelOutputExamples>
    </Section>
  );
};

function LegalBench() {
  return (
    <ValsPage pagename="legalbench">
      <PageTitle
        title="LegalBench"
        subtitle={
          <span>
            Evaluating language models on a wide range of <br /> open source
            legal reasoning tasks.
          </span>
        }
      />
      <Leaderboard
        modelData={legalBenchData}
        defaultSelection="overall"
        // Months are 0 indexed for god knows what reason
        lastUpdated={new Date(2024, 8, 30)}
      />
      <div className="space-y-10 pt-28">
        <Partners />
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        <NotableMentions />
        <ModelExamples />
        <Quirks />
        <Methodology />
      </div>
    </ValsPage>
  );
}

export default LegalBench;
