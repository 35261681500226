import Section from "../components/Section";
import ValsPage from "../components/ValsPage";

export default function AboutPage() {
  return (
    <ValsPage
      pagename="about"
      commentsEnabled={false}
      tableOfContentsEnabled={false}
      showNames={true}
    >
      <tr className="border-b-[1px] pb-4">
        {/* Copied from PageTitle.tsx */}
        <p className="font-serif text-xl md:text-3xl text-black font-semibold pt-20 pb-4">
          About
        </p>
      </tr>

      <div className="space-y-10 pt-16 pb-8">
        <Section id="philosophy" title="Motivation">
          <div className="space-y-4">
            <p>
              Model benchmarks today are seriously lacking. Popular benchmarks
              today for reporting model performance are based on contrived
              academic datasets. It is far more relevant to study how models
              perform on industry-specific tasks where these models will be
              used.{" "}
            </p>
            <p>
              Live leaderboards are often compromised. Researchers release
              datasets openly but this data is integrated into pre-training
              corpora making the evaluation results inaccurate. Bad actors
              fine-tune their models on evaluation sets making openly hosted
              leaderboards irrelevant.
            </p>
            <p>
              The results posted by companies building the models are biased.
              Each time large language model providers share results for a new
              model developed they do so with cherry-picked demo examples or
              with an evaluation regimen they have optimized the model to
              perform well in.
            </p>
          </div>
        </Section>
        <Section id="future-plans" title="Plans">
          <div className="space-y-4">
            <p>
              Because of these problems, we are building custom benchmarks for
              specific tasks that mimic real industry use cases. To avoid
              dataset leakage, we keep the data we use private and secure. We
              review these models as a neutral third-party, meaning we provide
              unbiased evaluation, and do not cherry-pick tasks. We work closely
              with researcher and industry members, but intend our reports to be
              accessible by general audiences.
            </p>
            <p>
              We are continually expanding the scope of our benchmarks to
              include more domains and task types, while evaluating more
              language model methods as they are made available. Reach out if
              you have an interest in contributing or have any ideas we should
              consider.
            </p>
          </div>
        </Section>
        <Section id="future-plans" title="Platform">
          <div className="space-y-4">
            <p>
              We use our own evaluation platform to create these benchmarks. It
              allows us to collect review criteria from subject-matter experts,
              then run evaluation of any LLM model, at scale. Not only can this
              platform expose model performance on these general domains, it can
              also evaluate any LLM application on task-specific data. We
              currently are extending early access to this platform to a few
              groups.
            </p>
          </div>
        </Section>
        {/* TODO: Remove the disqus on this page somehow */}
      </div>
    </ValsPage>
  );
}
