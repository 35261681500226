import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import nameToIconUntyped from "../data/metadata/icons.json";
import namedata from "../data/metadata/names.json";
import pricingdata from "../data/metadata/pricingdata.json";
import { ColumnDef, RowDef } from "./types";

const nameToIcon = nameToIconUntyped as { [key: string]: string };

interface TableProps {
  columns: ColumnDef[];
  data: RowDef[];
  sortby: (key: keyof RowDef) => void;
  page: number;
  sortDirection: {
    [key: keyof RowDef]: boolean | undefined;
  };
}

function formatRowEntry(row: RowDef, key: keyof RowDef) {
  if (key === "accuracy") {
    return ((row[key] as number) * 100).toFixed(1) + " %";
  } else if (key === "costIn") {
    let cost = (pricingdata.input_pricing as any)[row["model"]];
    return "$" + cost.toFixed(2) + " / M";
  } else if (key === "costOut") {
    let cost = (pricingdata.output_pricing as any)[row["model"]];
    return "$" + cost.toFixed(2) + " / M";
  } else if (key === "latency") {
    return (row[key] as number).toFixed(2) + " s";
  } else if (key === "model") {
    return (namedata as any)[row[key]];
  } else if (key === "company") {
    let logoPath = nameToIcon[row["model"]] ? nameToIcon[row["model"]] : "";
    return (
      <img src={logoPath} className="object-scale-down h-[24px] w-[24px]" />
    );
  } else {
    return row[key];
  }
}

function LeaderboardTable(props: TableProps) {
  const isDesktop = useMediaQuery({ minWidth: 600 });

  return (
    <table className=" md:w-[700px] md:h-[700px] bg-white border-4 border-black">
      <thead>
        <tr className="border-b-2 h-[40px] md:h-[62px]">
          {props.columns.map((column) => (
            <th
              onClick={() => props.sortby(column.accessor)}
              key={column.Header}
              className={column.accessor === "rank" ? "md:w-10" : ""}
            >
              {isDesktop && (
                <button className="text-xs md:text-base">
                  {column.Header}
                </button>
              )}
              {!isDesktop && (
                <button className="text-xs md:text-base">
                  {column.MobileHeader}
                </button>
              )}
              {props.sortDirection[column.accessor] !== undefined &&
                props.sortDirection[column.accessor] && <ArrowDropUp />}
              {props.sortDirection[column.accessor] !== undefined &&
                !props.sortDirection[column.accessor] && <ArrowDropDown />}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data
          .slice((props.page - 1) * 10, props.page * 10)
          .map((row: RowDef, index: number) => (
            <tr key={row.rank} className="border-b-[1px] h-[40px] md:h-[62px]">
              {props.columns.map((column: ColumnDef) => (
                <td
                  key={column.accessor}
                  className="text-center align-middle text-sm md:text-base"
                >
                  {column.accessor === "rank"
                    ? index + (props.page - 1) * 10 + 1
                    : formatRowEntry(row, column.accessor)}
                </td>
              ))}
            </tr>
          ))}
        {/* Add blank rows to pad the table to 10 rows */}
        {Array.from(
          {
            length:
              10 -
              props.data.slice((props.page - 1) * 10, props.page * 10).length,
          },
          (_, index) => (
            <tr key={`blank-${index}`}>
              {props.columns.map((column: ColumnDef, columnIndex: number) => (
                <td
                  key={columnIndex}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  &nbsp;
                </td>
              ))}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
}

export default LeaderboardTable;
