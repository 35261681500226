import { Link, useLocation } from "react-router-dom";
import "../styles/navbar.css";

function Navlink(props: any) {
  return (
    <Link
      to={props.to}
      className={"navbar-link"}
      // activeClassName={"navbar-link active"}
    >
      {props.children}
    </Link>
  );
}

function NavlinkSub(props: any) {
  return (
    <Link
      to={props.to}
      className={"navbar-link-sub"}
      //   activeClassName={"navbar-link-sub active"}
    >
      {props.children}
    </Link>
  );
}

export default function Navbar() {
  const location = useLocation();

  return (
    <div className="navbar">
      <Link to="/">
        <div className="navbar-brand">
          <img
            src={"valsai_icon.png"}
            alt="Vals.ai Icon"
            className="object-scale-down h-[50px]"
          />
        </div>
      </Link>
      <div className="navbar-element-wrap">
        <div className="navbar-elements">
          <div className="dropdown bg-background">
            <a className="navbar-link">Datasets</a>
            <div className="dropdown-content">
              <NavlinkSub bold to="/legalbench">
                LegalBench
              </NavlinkSub>
              <NavlinkSub bold to="/contractlaw">
                ContractLaw
              </NavlinkSub>
              <NavlinkSub bold to="/taxeval">
                TaxEval
              </NavlinkSub>
              <NavlinkSub bold to="/corpfin">
                CorpFin
              </NavlinkSub>
              <NavlinkSub bold to="/caselaw">
                CaseLaw
              </NavlinkSub>
            </div>
          </div>

          <Navlink bold to="/about">
            About
          </Navlink>
        </div>
      </div>
    </div>
  );
}
