import { fadeInUp } from "../util/animations";

function ContactUsButton({ href }: { href: string }) {
  return (
    <a href={href}>
      <button className="bg-accentgreen text-white px-6 h-[45px] rounded-md">
        Contact Us
      </button>
    </a>
  );
}

export default function ContactUs() {
  return fadeInUp(
    <div className="flex flex-col items-center">
      <p className="font-serif text-2xl text-black pt-12 font-bold">
        Get in Touch
      </p>
      <p className="body text-center italic pt-2">
        Want to see a private report like this on your own data?
      </p>
      <div className="flex mt-8 ">
        <ContactUsButton href="mailto:contact@vals.ai?subject=[Vals.ai Inquiry]: " />
      </div>
    </div>
  );
}

const MAIL_TO_HREF =
  "mailto:contact@vals.ai,rayan@vals.ai,langston@vals.ai,tara@vals.ai,jeroen@legaltechnologyhub.com,nikki@legaltechnologyhub.com?subject=[Vals.ai Inquiry]: ";

export function ContactUsVLair() {
  return fadeInUp(
    <div className="flex flex-col items-center">
      <p className="font-serif text-2xl text-black pt-12 font-bold">
        Get in Touch
      </p>
      <p className="body text-center italic pt-2 md:px-16">
        If you are a lawyer or work at a law firms that would like to contribute
        questions, please get in touch. We are also reviewing additional legal
        tech vendors on a case by case basis.
      </p>
      <p className="body text-center italic pt-2 md:px-16">
        Feel free to share any questions or ideas with our team.
      </p>
      <div className="flex mt-8 ">
        <ContactUsButton href={MAIL_TO_HREF} />
      </div>
    </div>
  );
}

export function ContactUsVLairMinimal() {
  return fadeInUp(
    <div className="flex flex-col items-center">
      <ContactUsButton href={MAIL_TO_HREF} />
    </div>
  );
}
