import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import taxEvalData from "../data/results/taxeval_results.json";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          o1 preview and o1 Mini performed far, far, better than other models on
          this dataset. They are much better at handling the numerical
          capabilities required for many of the tasks, and logically chaining
          the multiple steps required for a given calculation.
        </li>
        <li>
          In general, tax questions are a very challenging domain for Large
          Language Models. Most struggled across the board, but especially with
          math reasoning tasks.
        </li>
        <li>
          The upgraded Claude 3.5 Sonnet model performed better on multiple
          choice, but the previous version of the model performed better on free
          response.
        </li>
        <li>
          Apart from Llama 3/3.1, open-source models performed only marginally
          better than random guessing. It will take considerable work for these
          models to perform at a high standard on tax reasoning questions.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"o1 Preview"}
            icon={"logos/oai.png"}
            color={"accentgreen"}
            acc={73.2}
            costIn={15}
            costOut={60}
            latency={6.72}
            desc={
              "o1 preview commands an impressive 12 point lead against 4o and Sonnet on free response, and 13.5 percentage points on multiple choice; " +
              "o1 Mini actually performed better than o1 on multiple choice, albeit by a pretty small margin.; " +
              "Despite its impressive performance, the model was also extremely difficult to work with - we had to prepend additional instructions for it to follow formatting directions (see the Additional Notes section)."
            }
          />,
          75,
          75
        )}
        {fadeIn(
          <ModelCard
            name={"Claude 3.5 Sonnet"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={61.2}
            costIn={3}
            costOut={15}
            latency={0.63}
            desc={
              "Anthropic's recently released Claude 3.5 Sonnet bests GPT-4o by 2 percentage points on Multiple Choice, and ties it on the free response; " +
              "It also is significantly cheaper and faster than Opus.; " +
              "Even still, the model achieves a low objective accuracy indicating it cannot be used directly for tax applications. It is also still well behind o1 preview."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          There has been a considerable effort to measure language model
          performance in academic tasks and chatbot settings but these
          high-level benchmarks are contrived and not applicable to specific
          industry use cases. Further, model performance results released by LLM
          providers are highly biased - they are often manufactured to show
          state-of-the-art results.
        </p>
        <p className="text-section">
          Here we start to remedy this by reporting our third-party,
          application-specific findings and live leaderboard results on TaxEval.
          This dataset consists of multiple-choice and free-response US tax
          questions. Some of the major practice areas explored are as follows.
        </p>
        <ColorBlock color="violet">
          <ol className="text-section">
            <li>
              Income Tax:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Taxable income calculation: Understanding the differences
                  between accounting income and taxable income, including
                  permanent and temporary differences.
                </li>

                <li>
                  Tax rates: Applying the appropriate tax rates to calculate
                  income tax expense.
                </li>

                <li>
                  Deferred tax assets and liabilities: Recognizing and measuring
                  deferred tax assets and liabilities arising from temporary
                  differences.
                </li>

                <li>
                  Effective tax rate: Calculating and analyzing the effective
                  tax rate.
                </li>
              </ol>
            </li>
            <li>
              General Tax Concepts:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Matching principle: Applying the matching principle to
                  recognize tax expense in the same period as the related
                  revenue or expense.
                </li>

                <li>
                  Tax accounting methods: Understanding the differences between
                  cash-basis and accrual-basis accounting for tax purposes.
                </li>

                <li>
                  Discontinued operations: Calculating the after-tax gain or
                  loss on disposal of a discontinued operation.
                </li>

                <li>
                  Intangible assets: Understanding the tax implications of
                  impairment losses on intangible assets.
                </li>
              </ol>
            </li>
          </ol>
        </ColorBlock>
        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per question type are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/taxeval/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/taxeval/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          There is a significant divide between the o1 and Anthropic, and
          another divide between Anthropic models and others, particularly on
          the free-response questions. Gemini Pro and the GPT 3.5 models were of
          extremely middling performance. The other open source models were
          hopeless with accuracies near to pure guessing.
        </p>
        <br />
        <p className="text-section">
          Llama 3.1 405B performed well, but was not at the same level as Opus
          or o1 preview on this task. However, it was competitive, and a
          significant step up from both the previous Llama generations and other
          open source models. And even the Llama 2 models outcompeted Cohere.
        </p>

        {isDesktop && (
          <iframe
            src="plots/taxeval/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/taxeval/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The only three models that define the Pareto curve are all from OpenAI
          - GPT-4o Mini, GPT o1 Mini, and GPT o1 Preview. At every price point,
          these models are a considerable gap above their competition. It seems
          OpenAI has begun to crack the code on how to make models that have
          stronger math and reasoning performance.
        </p>
      </>
    </Section>
  );
};

const Quirks = () => {
  return (
    <Section title="Additional Notes" id="quirks">
      <div className="quirks">
        <ColorBlock color="green">
          <p className="text-section">
            <strong>o1 preview: </strong>The existing base prompt for both
            multiple choice and free response contains a directive for every
            model to "Answer concisely in one word, phrase or number." However,
            o1 preview, although getting the answer right, would consistently
            include far too much text for reasoning and description, which made
            evaluation difficult. Therefore, for o1 evaluation, we prepended
            every prompt with the following directive: "THE OUTPUT SHOULD BE ONE
            WORD, A FEW WORD PHRASE, NUMBER, OR A MONETARY FIGURE. DO NOT
            EXPLAIN REASONING."
          </p>
          <p className="text-section">
            GPT o1 also did not let us configure the temperature used for the
            evaluation.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These experiments were run over a broad data set of open tax domain
            questions, courtesy of Daniel Gross. These questions are quite
            challenging, including multiple sub-parts, multi-step calculations,
            and knowledge of tax law application. The original dataset was
            composed multiple-choice questions, with four options to choose from
            each. However, real applications will not be multiple choice.
            Therefore, we also created a version of the dataset in which models
            are prompted to give the answer as an open-ended free response text.
          </p>
          <p className="text-section">
            The multiple-choice answers were parsed and evaluated directly on
            accuracy. Note that a naive random model would have scored a 25%
            accuracy on the multiple-choice section.
          </p>
          <p className="text-section">
            For the free-response questions, we used an LLM-based
            auto-evaluation method to replace human review. This system judged
            whether the generated answer met the standard provided by the right
            answer. Reducing or eliminating human review costs allows for the
            creation of many additional open-form-response tasks, widening the
            range of possible future evaluations.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <p className="text-section">
        We show here one free response question, in which Opus and Gemini were
        incorrect, while GPT4 and o1 preview were correct. This question relates
        to what type of fund a library should use.
      </p>
      <br />
      <p className="text-section">
        This question is tricky, because both answers could be right, but an
        enterprise fund is a <em>better</em> answer. Enterprise funds are
        intended to be used for services that are wholly self sufficient.
        Furthermore, enterprise funds provide accrual accounting, which is
        useful for tracking revenue and expenses. Also, special revenue funds
        can only be used for a specific purpose, meaning if there is overage,
        the fund remains locked.
      </p>
      <br />
      <p className="text-section">
        Gemini just says "Governmental fund", which is a broad category of
        funds, rather than the specific type (Special revenue or Enterprise).
        However, this would not be useful to a user, as they would be interested
        in what type of governmental fund they should use.
      </p>
      <br />
      <ColorBlock color="beige">
        <p className="text-section">
          Prompt: Answer concisely in one word, phrase or number. King City
          Council will be establishing a library fund. Library fees are expected
          to cover 55% of the library's annual resource requirements. King has
          decided that an annual determination of net income is desirable in
          order to maintain management control and accountability over library.
          What type of fund should King establish in order to meet their
          measurement objectives?
        </p>
      </ColorBlock>
      <ModelOutputExamples
        names={["Opus", "GPT-4", "o1 preview", "Gemini 1.0"]}
      >
        <>
          <p className="text-section">
            <strong>Opus: &nbsp;</strong>Special revenue fund.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>GPT 4: &nbsp;</strong>Enterprise fund
          </p>
        </>
        <>
          <p className="text-section">
            <strong>o1 preview: &nbsp;</strong>Enterprise Fund
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Gemini 1.0: &nbsp;</strong>Governmental
          </p>
        </>
      </ModelOutputExamples>
    </Section>
  );
};

export default function TaxEval() {
  return (
    <ValsPage pagename="taxeval">
      <PageTitle
        title="TaxEval"
        subtitle={
          <span>Evaluating Language Models on Tax Domain Questions</span>
        }
      />
      <Leaderboard
        modelData={taxEvalData}
        defaultSelection="free_response"
        // Months are 0 indexed for god knows what reason

        lastUpdated={new Date(2024, 8, 30)}
      />
      <div className="page-content-container">
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        <Quirks />
        <Methodology />
        <ModelExamples />
      </div>
    </ValsPage>
  );
}
