type SectionProps = {
  // Unique ID for scrolling behavior
  id: string;
  title: string;
  children: any;
};

export default function Section(props: SectionProps) {
  return (
    <div>
      <h1 className="header" id={props.id}>
        {props.title}
      </h1>
      <div className="md:w-[800px]">{props.children}</div>
    </div>
  );
}
