import { MenuItem, Select, ThemeProvider, createTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";

type LeaderBoardSelectProps = {
  testSuiteId: string;
  onTestSuiteIdChange: (testSuiteId: string) => void;
  options: string[];
};

// Color palette
const theme = createTheme({
  // color scheme
  palette: {
    primary: {
      main: "#15151A",
    },
    secondary: {
      main: "#D9D9D9",
    },
  },
  // fonts
  typography: {
    fontFamily: ["Roboto", "Epilogue", "sans-serif"].join(","),
    h3: {
      fontSize: "28px",
      fontWeight: 500,
    },
  },
});

const LeaderBoardSelectSx = {
  width: "12em",
  height: "3em",
  color: "#15151A",
  bgcolor: "#FFFAF7",
  border: 3,
  borderRadius: "0px",
  padding: "1px",
  marginLeft: "10px",
  textAlign: "center",
  fontWeight: 600,
  fontSize: "14px",
  // icon color and button design
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#15151A",
  },
  ".MuiSelect-icon": {
    color: "#15151A",
    right: 15,
    size: "large",
  },
  "&:hover": {
    color: "#15151A",
    bgcolor: "#EEE4DB",
    ".MuiSelect-icon": {
      color: "#15151A",
    },
  },
};

export default function LeaderBoardSelect(props: LeaderBoardSelectProps) {
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <Select
          value={props.testSuiteId}
          label=""
          onChange={(e) => {
            const value = e.target.value as string;
            props.onTestSuiteIdChange(value);
          }}
          sx={LeaderBoardSelectSx}
        >
          {props.options.map((id: string) => {
            return (
              <MenuItem value={id} sx={{ fontWeight: 600, fontSize: "14px" }}>
                {id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
