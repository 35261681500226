import { BACKEND_ENDPOINT } from "../util/endpoints";

export async function sendJoinMailingListHttpRequest(email: string) {
  let requestOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };
  let response = await fetch(
    `${BACKEND_ENDPOINT}/join_mailing_list/`,
    requestOptions as any
  );
  if (response.status != 200) {
    let text = await response.text();
    throw Error("Joining Mailing list Failed. " + text);
  }
}
