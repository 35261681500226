import { useMediaQuery } from "react-responsive";

type ModelCardProps = {
  name: string;
  icon: string;
  color: string;
  acc: number;
  costIn: number;
  costOut: number;
  latency: number;
  desc: string;
};

export default function ModelCard(props: ModelCardProps) {
  const descList = props.desc.split("; "); // Split the string into an array
  const MobileCard = () => {
    return (
      <div className={`bg-white border-${props.color} border-4 p-4 space-x-4`}>
        <div className="flex justify-around pb-4">
          <img
            src={props.icon}
            className="object-scale-down w-[100px] md:w-[145px]"
          />

          <div className=" h-full items-center pt-4">
            <div className="w-full flex flex-col items-center content-center">
              <p className="text-xl font-semibold underline">{props.name}</p>
              <p className=" text-l font-semibold">Accuracy: {props.acc}%</p>
              <p className=" text-l font-semibold">
                Cost In: ${props.costIn}/M
              </p>
              <p className=" text-l font-semibold">
                Cost Out: ${props.costOut}/M
              </p>
              <p className=" text-l font-semibold">
                Latency: {props.latency} sec
              </p>
            </div>
          </div>
        </div>
        <p className="font-sans text-sm leading-relaxed">
          <ul className="list-disc">
            {descList.map((feature, index) => (
              <li key={index} className="font-sans text-sm leading-relaxed">
                {feature}
              </li>
            ))}
          </ul>
        </p>
      </div>
    );
  };

  const DesktopCard = () => {
    const descList = props.desc.split("; "); // Split the string into an array

    return (
      <div
        className={`flex bg-white border-${props.color} border-4 p-4 space-x-4 w-[800px]`}
      >
        <img
          src={props.icon}
          className="object-scale-down w-[100px] md:w-[145px]"
        />

        <div className="w-[200px] h-full justify-center items-center pt-4">
          <div className="w-full flex flex-col items-center ">
            <p className="text-xl font-semibold underline">{props.name}</p>
            <p className=" text-l font-semibold">Accuracy: {props.acc}%</p>
            <p className=" text-l font-semibold">Cost In: ${props.costIn}/M</p>
            <p className=" text-l font-semibold">
              Cost Out: ${props.costOut}/M
            </p>
            <p className=" text-l font-semibold">
              Latency: {props.latency} sec
            </p>
          </div>
        </div>
        <p className="w-[400px] font-sans text-sm leading-relaxed">
          <ul className="list-disc">
            {descList.map((feature, index) => (
              <li key={index} className="font-sans text-sm leading-relaxed">
                {feature}
              </li>
            ))}
          </ul>
        </p>
      </div>
    );
  };

  const isDesktop = useMediaQuery({ minWidth: 600 });

  return (
    <div>
      {isDesktop && <DesktopCard />}
      {!isDesktop && <MobileCard />}
    </div>
  );
}
