import { ReactElement } from "react";

type ColoredSectionProps = {
  color: "green" | "rose" | "beige" | "violet" | "blue";
  children: ReactElement | ReactElement[] | string | number;
};

const COLOR_TO_CLASS_MAP = {
  green: "bg-green-100",
  violet: "bg-violet-100",
  rose: "bg-rose-100",
  beige: "bg-[#E8DDD3]",
  blue: "bg-blue-100",
  orange: "bg-orange-200",
};

export default function ColorBlock(props: ColoredSectionProps) {
  return (
    <div className={"space-y-4 md:p-8 p-4 " + COLOR_TO_CLASS_MAP[props.color]}>
      {props.children}{" "}
    </div>
  );
}
