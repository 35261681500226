import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import data from "../data/results/canadiancaselaw_results.json";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          o1 preview performed the best - eking out a few percentage points of
          improvement over 3.5 Sonnet and GPT-4o. However, these few percentage
          points came at a pretty high cost, especially since this dataset
          involved longer documents (ergo higher token usage).
        </li>
        <li>
          Claude 3.5 Sonnet and GPT 4 were neck-in-neck - Claude won overall
          (with a slim margin), and on the "criminal" task. However, GPT 4o
          performed better on the "family" task.
        </li>
        <li>
          The Llama 3.1 70B model performed admirably well - outperforming other
          source models and competitive with many of the top closed source
          models. Llama 405B had a maximum context window of 4096 tokens via
          Together AI, so it was not included.
        </li>
        <li>
          In one or two cases, the models actually produced a <em>better</em>{" "}
          answer than the human-provided ground-truth (discovered during quality
          assurance pass).
        </li>
        <li>
          A common failure mode of the models was that they would find{" "}
          <em>one</em> relevant section of the document, but not <em>all</em>{" "}
          relevant sections of the document. They also often base their answers
          on their general knowledge corpus, rather than on the specific
          document provided (as they were instructed to do).
        </li>
        <li>
          Then best models generally had a high objective accuracy - meaning
          they are good candidates for the task, especially with additional
          prompt tuning or productionization.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"o1 preview"}
            icon={"logos/oai.png"}
            color={"accentgreen"}
            acc={87.3}
            costIn={15}
            costOut={60}
            latency={19.4}
            desc={
              "o1 preview performed the best on this task, with a 2-percentage-point margin over Sonnet; " +
              "On the long documents in this dataset, it's quite slow - taking almost 20s on average.; " +
              "It's also quite expensive, especially given the longer-context nature of this task"
            }
          />,
          75,
          75
        )}
        {fadeIn(
          <ModelCard
            name={"Claude 3.5 Sonnet"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={85.0}
            costIn={3}
            costOut={15}
            latency={4.33}
            desc={
              "Anthropic's recently released Claude 3.5 Sonnet was second highest performing model, and #1 on the 'criminal' subtask.; " +
              "What's more, it achieved this performance at a mid-range price point - far cheaper than its predecessor Opus; " +
              "When it got questions wrong, it was often because it picked a closely related segment of the case that was a less optimal citation."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          Our previous two custom datasets, CorpFin and ContractLaw, were both
          transactional use cases. In this report we present our first study of
          the use of LLMs in litigation -- law as it relates to public court
          systems.
        </p>
        <p className="text-section">
          In collaboration with legaltech startup{" "}
          <a href="https://jurisage.com/">Jurisage</a>, we announce the creation
          of our latest dataset, "Case Law". In litigation, it is common to
          determine how a specific legal question was answered in a wide set of
          precedent cases. For example, you may want to determine the amount of
          child support that was required on several family law cases that had
          similar fact patterns.
        </p>
        <p className="text-section">
          We ask these questions to models provided with case law data provided
          by Jurisage, across two areas of law: family and criminal. This
          dataset is <strong>private</strong> - the case data is not publicly
          accessible, and all cases used were from June 2024, after the models'
          training cutoff dates. The Q&A pairs were written with support by
          Jurisage and are completely novel.
        </p>
        <p className="text-section">
          Case law in the US in Canada is extremely hard to use at-scale. Strict
          licensing requirements prevents LLMs from being trained on them.
          Groups that have collected a large amount of case history only provide
          it for legal use at a massive expense.
        </p>
        <p className="text-section">
          The benefit of testing models on this data is multifold. First,
          lawyers in practice will ask questions of models in reference to
          recent cases that foundation models or applications will not have been
          exposed to in their training. Second, the majority of legal LLM
          evaluation has been done for US law only. Here we expand to study
          their use on international legal systems.
        </p>
        <ColorBlock color="blue">
          <p className="text-section">
            <b>Family: </b> Matters related to divorce, custody, child support,
            and emergency protection orders (EPO).
          </p>
          <p className="text-section">
            <b>Criminal: </b> Matters such as detention, validity of guilty
            pleas, ability to adduce fresh evidence on appeal, burden of proof,
            mandatory minimums, and sentencing objectives.
          </p>
        </ColorBlock>
        <p className="text-section">
          Each question is asked against a case document (roughly 20k - 30k
          tokens). The questions are split up into two types - extractive and
          summative. Extractive questions are checked to ensure they nearly
          exactly match the ground truth, where summative questions are checked
          to ensure they include the most important semantic points.
        </p>
        <ColorBlock color="violet">
          <p className="text-section">
            <b>Example Summative Question: </b>How are "access costs"
            characterized in situations where the parents live in different
            cities or provinces? Answer the question only by paraphrasing
            excerpts from the document. Your answer should be solely grounded in
            information contained in the document.
          </p>
          <p className="text-section">
            <b>Example Extractive Question:</b> Was the payor's child support
            reduced? Answer the question only with an excerpt or multiple
            excerpts from the document - do not include additional description
            or explanation.
          </p>
        </ColorBlock>

        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per question type are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/canadiancaselaw/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/canadiancaselaw/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The total spread between the top-performing and worst-performing was
          29.2 percentage points. Clearly, this is a dataset that strong models
          can perform well - but weak models struggle with. o1 was the winner on
          aggregate, but 3.5 Sonnet was able to claim the top spot on the
          "criminal" subtask.
        </p>
        <br />
        <p className="text-section">
          Interestingly enough, Claude 3.0 Sonnet outperforms Claude 3.0 Opus -
          perhaps it is better suited towards the extraction tasks, despite (or
          because of) being less powerful.
        </p>
        <br />
        <p className="text-section">
          For its size, Mixtral punched above its weight - beating out both
          Cohere and Gemini. It is also one of the few open-source models with a
          32000 token context-window. However, it was overshadowed by the
          recently released Llama 3.1 models.
        </p>
        <br />

        <p className="text-section"></p>
        {isDesktop && (
          <iframe
            src="plots/canadiancaselaw/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/canadiancaselaw/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}

        <p className="text-section">
          If both cost and latency are no object, then GPT-o1 seems like the
          clear cut winner. However, very rarely is this the case -- and on a
          document-based dataset like this, o1 preview would rapidly get
          expensive.
        </p>
        <br />
        <p className="text-section">
          At its price point, therefore, Claude 3.5 Sonnet is also compelling.
          It has close performance to o1 preview, but at a fraction of the cost.
          We will see how Anthropic responds to o1 - by continuing to make
          cheaper models, or by releasing another expensive, powerful model (a
          la Opus).
        </p>
        <br />
        <p className="text-section">
          Although Cohere Command R is also technically on the Pareto curve, it
          is the third worst performing model - so it would likely only be
          useful in an area where cost is a very high priority. Even so, it
          seems more likely that Mixtral or Llama 3.1 would be a better option
          in practice.
        </p>
      </>
    </Section>
  );
};

const NotableMentions = () => {
  return (
    <Section title="Notable Mentions" id="notable-mentions">
      {fadeIn(
        <ModelCard
          name={"Llama 3.1 Instruct"}
          icon={"logos/meta.png"}
          color={"accentblue"}
          acc={77.1}
          costIn={0.88}
          costOut={0.88}
          latency={6.41}
          desc={
            "Llama 3.1 was the top-performing model that was not produced by Anthropic or OpenAI; " +
            "It beat out both Gemini models, and both Cohere Command models; " +
            " It was also one of the cheapest models - a fraction of the cost of both GPT-4o and Claude 3.5 Sonnet; " +
            " Although 70B has a context length of 128k tokens, 405B is still limited to 4096 tokens (when queried via Together AI)."
          }
        />,
        100
      )}
    </Section>
  );
};

const Partners = () => {
  let outerStyling =
    "flex justify-center items-center border-[3px] border-[#D0C7BF] rounded-full w-[70px] h-[70px] md:w-[150px] md:h-[150px] md:border-[6px]";
  return (
    <div>
      <h1 className="header" id="partners-in-evaluation">
        Partners in Evaluation
      </h1>
      <div className="flex justify-around pt-2">
        {fadeIn(
          <a href="https://jurisage.com/" target="_blank">
            <div className={outerStyling + " bg-[#ffffff]"}>
              <img
                src={"partner_logos/jurisage.jpg"}
                className="object-scale-down w-[45px] md:w-[90px]"
              />
            </div>
          </a>,
          200
        )}
        {fadeIn(
          <a href="https://www.together.ai/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/tai.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          400
        )}
        {fadeIn(
          <a
            href="https://law.stanford.edu/codex-the-stanford-center-for-legal-informatics/"
            target="_blank"
          >
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/codex.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          0
        )}
        {/* <div className={outerStyling}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[40px]"
            />
          </div> */}
      </div>
    </div>
  );
};

const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These question / answer pairs were generated by a trained legal
            professional, who answers questions like these as part of their
            day-to-day professional work. The cases to ask the questions over
            were selected from a random sample of family and criminal cases over
            the preceding month.
          </p>

          <p className="text-section">
            We used an LLM-based auto-evaluation method to review the outputs.
            This system judged whether the generated answer met the standard
            provided by the right answer. To ensure the accuracy of the
            auto-eval, 30% of the graded questions were spot-checked by the
            legal professional responsible for creating the dataset.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs.
          </p>

          <p className="text-section">
            Both the entire document and the prompt were passed to the model
            directly in-context. These documents were roughly 15000 to 350000
            tokens in length. Therefore, only models that have at least this
            large of a context window were tested.
          </p>

          <p className="text-section">
            Because there was stochasticity in the model outputs, even when the
            temperature was set to zero and the seed was fixed, the average of
            five evaluation runs was taken to produce the final results.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>

          <p className="text-section">
            GPT-4o1 did not contain a method to control the temperature (or
            include a system prompt).
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <p className="text-section">
        Here is an example of one of the extraction questions provided. In this
        case, R. v. Jones, 2024 ONSC 3181, the respondent Mr. Jones was carrying
        a handgun while on weapons prohibition and was also in possession of
        narcotics.
      </p>
      <br />

      <p className="text-section">
        The prompt asks what "mitigating factors" exist - reasons to potentially
        give Mr. Jones a lower sentence. There are two relevant passages we'd
        expect the model to give - a correct answer would provide both,
        verbatim.
      </p>
      <br />
      <p className="text-section">
        The first is a section saying that there are not many mitigating
        factors, but summarizes the few that exist: he was remorseful, he did
        have an uncontested trial, he came from a significantly disadvantaged
        background, and he experienced harsh pre-trial conditions. The second
        expected excerpt is an elaboration on the harsh pre-trial conditions.
      </p>
      <br />

      <p className="text-section">
        Generally, all of the models are able to accurately pull the first
        excerpt (for example, o1 successfully pulls both excerpts). However,
        only some of the models were able to accurately include the second
        expected excerpt.
      </p>
      <br />

      <ColorBlock color="beige">
        <>
          <p className="text-section">
            <b>Prompt:</b> What did the court consider as mitigating factors?
            Answer the question only with an excerpt or multiple excerpts from
            the document - do not include additional description or explanation.
          </p>
          <p className="text-section">
            <b>Expected Excerpt 1: </b> Regrettably, there are few mitigating
            factors in this case. Mr. Jones did not plead guilty, but he had an
            uncontested trial after an unsuccessful Charter motion. Proceeding
            this way certainly saved the state the resources required to
            prosecute him, including court time, which is at a premium in this
            post-pandemic world. Although it is not as mitigating as a full
            guilty plea before trials or motions, it is certainly mitigating.
            Perhaps more importantly, Mr. Jones appears to be remorseful, and I
            take that into account. It is also mitigating that Mr. Jones has the
            support of his family. The EPSR was helpful in setting out some of
            the mitigating factors associated with Mr. Jones’ background. He had
            a significantly deprived upbring. He had few advantages and many
            disadvantages. There was nothing in his previous life to set him up
            for success, and much to set him up for failure. That has an impact
            on his moral blameworthiness. It is also mitigating that Mr. Jones
            spent a great deal of time in harsh pre-trial detention, where
            conditions were difficult. I will have more to say about this
            mitigating factor in a moment.
          </p>
          <p className="text-section">
            <b>Expected Excerpt 2: </b> I take these harsh conditions of custody
            into account as a mitigating factor: R. v. Duncan, supra; R. v.
            Marshall, 2021 ONCA 344.
          </p>
        </>
      </ColorBlock>
      <ModelOutputExamples
        names={[
          "o1 preview",
          "Claude 3.5 Sonnet",
          "GPT-4",
          "Gemini 1.5",
          "Cohere Command R+",
        ]}
      >
        <>
          <p className="text-section">
            "26. Regrettably, there are few mitigating factors in this case. Mr.
            Jones did not plead guilty, but he had an uncontested trial after an
            unsuccessful Charter motion. Proceeding this way certainly saved the
            state the resources required to prosecute him, including court time,
            which is at a premium in this post-pandemic world. Although it is
            not as mitigating as a full guilty plea before trials or motions, it
            is certainly mitigating. Perhaps more importantly, Mr. Jones appears
            to be remorseful, and I take that into account. It is also
            mitigating that Mr. Jones has the support of his family. The EPSR
            was helpful in setting out some of the mitigating factors associated
            with Mr. Jones' background. He had a significantly deprived
            upbringing. He had few advantages and many disadvantages. There was
            nothing in his previous life to set him up for success, and much to
            set him up for failure. That has an impact on his moral
            blameworthiness. It is also mitigating that Mr. Jones spent a great
            deal of time in harsh pre-trial detention, where conditions were
            difficult. I will have more to say about this mitigating factor in a
            moment."
            <br /> <br />
            "35. I take these harsh conditions of custody into account as a
            mitigating factor: R. v. Duncan, supra; R. v. Marshall, 2021 ONCA
            344."
            <br /> <br />
            "36. I also note that the EPSR was ordered on the day that Mr. Jones
            had his uncontested trial before me. It was originally supposed to
            be ready in the fall of 2023. Through no fault of his, Mr. Jones was
            required to wait in remand while the EPSR was prepared. I find that
            this is also a mitigating factor. In R. v. Hartling, 2020 ONCA 243,
            the Court of Appeal reduced a sentence by five months because of the
            time it took to obtain a Gladue report – as in this case, well over
            a year. I will treat the lengthy time it took to obtain the EPSR as
            a mitigating factor."
            <br /> <br />
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Claude 3.5 Sonnet: &nbsp;</strong>The court considered the
            following as mitigating factors, based on these excerpts:
            "Proceeding this way certainly saved the state the resources
            required to prosecute him, including court time, which is at a
            premium in this post-pandemic world. Although it is not as
            mitigating as a full guilty plea before trials or motions, it is
            certainly mitigating. Perhaps more importantly, Mr. Jones appears to
            be remorseful, and I take that into account. It is also mitigating
            that Mr. Jones has the support of his family. The EPSR was helpful
            in setting out some of the mitigating factors associated with Mr.
            Jones' background. He had a significantly deprived upbring. He had
            few advantages and many disadvantages. There was nothing in his
            previous life to set him up for success, and much to set him up for
            failure. That has an impact on his moral blameworthiness. It is also
            mitigating that Mr. Jones spent a great deal of time in harsh
            pre-trial detention, where conditions were difficult." "I take these
            harsh conditions of custody into account as a mitigating factor" "I
            find that this is also a mitigating factor. In R. v. Hartling, 2020
            ONCA 243, the Court of Appeal reduced a sentence by five months
            because of the time it took to obtain a Gladue report – as in this
            case, well over a year. I will treat the lengthy time it took to
            obtain the EPSR as a mitigating factor."
          </p>
        </>
        <>
          <p className="text-section">
            <strong>GPT 4: &nbsp;</strong>26. Regrettably, there are few
            mitigating factors in this case. Mr. Jones did not plead guilty, but
            he had an uncontested trial after an unsuccessful Charter motion.
            Proceeding this way certainly saved the state the resources required
            to prosecute him, including court time, which is at a premium in
            this post-pandemic world. Although it is not as mitigating as a full
            guilty plea before trials or motions, it is certainly mitigating.
            Perhaps more importantly, Mr. Jones appears to be remorseful, and I
            take that into account. It is also mitigating that Mr. Jones has the
            support of his family. The EPSR was helpful in setting out some of
            the mitigating factors associated with Mr. Jones’ background. He had
            a significantly deprived upbringing. He had few advantages and many
            disadvantages. There was nothing in his previous life to set him up
            for success, and much to set him up for failure. That has an impact
            on his moral blameworthiness. It is also mitigating that Mr. Jones
            spent a great deal of time in harsh pre-trial detention, where
            conditions were difficult. I will have more to say about this
            mitigating factor in a moment. 35. I take these harsh conditions of
            custody into account as a mitigating factor: R. v. Duncan, supra; R.
            v. Marshall, 2021 ONCA 344. 36. I also note that the EPSR was
            ordered on the day that Mr. Jones had his uncontested trial before
            me. It was originally supposed to be ready in the fall of 2023.
            Through no fault of his, Mr. Jones was required to wait in remand
            while the EPSR was prepared. I find that this is also a mitigating
            factor. In R. v. Hartling, 2020 ONCA 243, the Court of Appeal
            reduced a sentence by five months because of the time it took to
            obtain a Gladue report – as in this case, well over a year. I will
            treat the lengthy time it took to obtain the EPSR as a mitigating
            factor.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Gemini 1.5: &nbsp;</strong>26. Regrettably, there are few
            mitigating factors in this case. Mr. Jones did not plead guilty, but
            he had an uncontested trial after an unsuccessful Charter motion.
            Proceeding this way certainly saved the state the resources required
            to prosecute him, including court time, which is at a premium in
            this post-pandemic world. Although it is not as mitigating as a full
            guilty plea before trials or motions, it is certainly mitigating.
            Perhaps more importantly, Mr. Jones appears to be remorseful, and I
            take that into account. It is also mitigating that Mr. Jones has the
            support of his family. The EPSR was helpful in setting out some of
            the mitigating factors associated with Mr. Jones’ background. He had
            a significantly deprived upbring. He had few advantages and many
            disadvantages. There was nothing in his previous life to set him up
            for success, and much to set him up for failure. That has an impact
            on his moral blameworthiness. It is also mitigating that Mr. Jones
            spent a great deal of time in harsh pre-trial detention, where
            conditions were difficult. 27. I also note that the EPSR was ordered
            on the day that Mr. Jones had his uncontested trial before me. It
            was originally supposed to be ready in the fall of 2023. Through no
            fault of his, Mr. Jones was required to wait in remand while the
            EPSR was prepared. I find that this is also a mitigating factor.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Cohere Command R+: &nbsp;</strong>Regrettably, there are few
            mitigating factors in this case. Mr. Jones did not plead guilty, but
            he had an uncontested trial after an unsuccessful Charter motion.
            Proceeding this way certainly saved the state the resources required
            to prosecute him, including court time, which is at a premium in
            this post-pandemic world. Although it is not as mitigating as a full
            guilty plea before trials or motions, it is certainly mitigating.
            Perhaps more importantly, Mr. Jones appears to be remorseful, and I
            take that into account. It is also mitigating that Mr. Jones has the
            support of his family. The EPSR was helpful in setting out some of
            the mitigating factors associated with Mr. Jones’ background. He had
            a significantly deprived upbringing. He had few advantages and many
            disadvantages. There was nothing in his previous life to set him up
            for success, and much to set him up for failure. That has an impact
            on his moral blameworthiness. It is also mitigating that Mr. Jones
            spent a great deal of time in harsh pre-trial detention, where
            conditions were difficult. I will have more to say about this
            mitigating factor in a moment.
            <br />
            ...
            <br />I also note that the EPSR was ordered on the day that Mr.
            Jones had his uncontested trial before me. It was originally
            supposed to be ready in the fall of 2023. Through no fault of his,
            Mr. Jones was required to wait in remand while the EPSR was
            prepared. I find that this is also a mitigating factor. In R. v.
            Hartling, 2020 ONCA 243, the Court of Appeal reduced a sentence by
            five months because of the time it took to obtain a Gladue report –
            as in this case, well over a year. I will treat the lengthy time it
            took to obtain the EPSR as a mitigating factor.
          </p>
        </>
      </ModelOutputExamples>
    </Section>
  );
};

export default function CaseLaw() {
  return (
    <ValsPage pagename="caselaw">
      <PageTitle
        title="Case Law"
        subtitle={
          <span>
            Private question-answer dataset over Canadian court cases.{" "}
          </span>
        }
      />
      <Leaderboard
        modelData={data}
        defaultSelection="overall"
        // Months are 0 indexed for god knows what reason
        lastUpdated={new Date(2024, 8, 30)}
      />
      <div className="page-content-container">
        <Partners />
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        <NotableMentions />
        {/* <Quirks /> */}
        <Methodology />
        <ModelExamples />
      </div>
    </ValsPage>
  );
}
