import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import { useEffect, useState } from "react";
import pricingdata from "../data/metadata/pricingdata.json";
import LeaderBoardSelect from "./LeaderboardSelect";
import LeaderBoardTable from "./leaderboardTable";

import { ColumnDef, RowDef } from "./types";

const columns: ColumnDef[] = [
  { Header: "", MobileHeader: "", accessor: "rank" },
  { Header: "", MobileHeader: "", accessor: "company" },
  { Header: "MODEL", MobileHeader: "MODEL", accessor: "model" },
  { Header: "ACCURACY", MobileHeader: "ACC", accessor: "accuracy" },
  { Header: "COST IN", MobileHeader: "IN", accessor: "costIn" },
  { Header: "COST OUT", MobileHeader: "OUT", accessor: "costOut" },
  { Header: "LATENCY", MobileHeader: "LAT", accessor: "latency" },
];

type LeaderboardProps = {
  modelData: any;
  defaultSelection: string;
  lastUpdated: Date;
};

export default function Leaderboard(props: LeaderboardProps) {
  const [testSuiteId, setTestSuiteId] = useState(props.defaultSelection);
  const [leaderboardData, setLeaderboardData] = useState<RowDef[]>([]);
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState<{
    [key: keyof RowDef]: boolean | undefined;
  }>({
    rank: undefined,
    model: false,
    accuracy: false,
    costIn: true,
    costOut: true,
    latency: true,
  });

  const [copiedLink, setCopiedLink] = useState(false);

  useEffect(() => {
    const sortedData = props.modelData[props.defaultSelection].sort(
      (a: any, b: any) => b.accuracy - a.accuracy
    );
    setLeaderboardData(sortedData);
  }, [props.modelData]);

  const sortby = (key: keyof RowDef) => {
    if (key === "rank" || key === "company") {
      return;
    }

    let tempData = [...leaderboardData];
    let dir = sortDirection[key] ? -1 : 1;
    setSortDirection({ ...sortDirection, [key]: !sortDirection[key] });
    tempData = tempData.sort((a, b) => {
      let a_value = a[key];
      let b_value = b[key];

      // We need to handle cost as a special case since it's not in the data itself.
      if (key === "costIn") {
        a_value = (pricingdata.input_pricing as any)[a["model"]];
        b_value = (pricingdata.input_pricing as any)[b["model"]];
      } else if (key === "costOut") {
        a_value = (pricingdata.output_pricing as any)[a["model"]];
        b_value = (pricingdata.output_pricing as any)[b["model"]];
      }

      if (a_value > b_value) {
        return dir;
      } else if (a_value < b_value) {
        return dir * -1;
      } else {
        return 0;
      }
    });
    setLeaderboardData(tempData);
  };

  function onTestSuiteIdChange(newSuiteId: string) {
    setTestSuiteId(newSuiteId);
    const sortedByAccuracy = (props.modelData as any)[newSuiteId].sort(
      (a: any, b: any) => b.accuracy - a.accuracy
    );
    setLeaderboardData(sortedByAccuracy);
  }

  const SocialLinks = () => {
    let containerStyle =
      "w-[35px] h-[35px] md:w-[42px] md:h-[42px] bg-[#D0C7BF] flex justify-center items-center rounded-full";

    return (
      <div className="flex justify-between space-x-4">
        <a
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(window.location.href).then(() => {
              setCopiedLink(true);
            });
          }}
          style={{ cursor: "pointer" }}
        >
          <div className={containerStyle}>
            {!copiedLink && <LinkIcon />}
            {copiedLink && <CheckIcon />}
          </div>
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            window.location.href
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={containerStyle}>
            <LinkedInIcon />
          </div>
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={containerStyle}>
            <XIcon />
          </div>
        </a>
      </div>
    );
  };

  let arrowsStyle =
    "w-[35px] h-[35px] md:w-[42px] md:h-[42px] bg-white border-2 md:border-4 border-black  flex justify-center items-center";

  return (
    <div className="pt-28">
      <div className="md:w-[700px] flex justify-between py-2">
        <SocialLinks />
        <p className="font-serif text-black text-opacity-90 pt-2">
          Last Updated{" "}
          {props.lastUpdated.toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          {/* February 16, 2024 */}
        </p>
      </div>

      <LeaderBoardTable
        page={page}
        columns={columns}
        data={leaderboardData}
        sortby={sortby}
        sortDirection={sortDirection}
      />

      <div className="flex justify-between pt-2 mx-2">
        <div className="flex space-x-2">
          <div className={arrowsStyle}>
            <button onClick={() => setPage(Math.max(page - 1, 1))}>
              <ArrowLeftIcon fontSize="large" />
            </button>
          </div>
          <div className={arrowsStyle}>
            <button
              onClick={() =>
                setPage(
                  Math.min(page + 1, Math.ceil(leaderboardData.length / 10))
                )
              }
            >
              <ArrowRightIcon fontSize="large" />
            </button>
          </div>
        </div>
        <LeaderBoardSelect
          testSuiteId={testSuiteId}
          onTestSuiteIdChange={onTestSuiteIdChange}
          options={Object.keys(props.modelData)}
        />
      </div>
    </div>
  );
}
