import { useState } from "react";
import { sendJoinMailingListHttpRequest } from "../http/http_requests";

const styles = {
  error_snackbar: {},
  success_snackbar: {},
};

function isEmail(email: string) {
  // Regular expression to match email pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export type SnackbarStateType = {
  open: boolean;
  success: boolean;
  message: string;
};

type MailingListProps = {
  snackbarState: SnackbarStateType;
  setSnackbarState: (state: SnackbarStateType) => void;
};

export default function MailingList({
  snackbarState,
  setSnackbarState,
}: MailingListProps) {
  const [email, setEmail] = useState("");

  function onSubscribeClicked(e: any) {
    if (isEmail(email)) {
      sendJoinMailingListHttpRequest(email).then(
        () => {
          setSnackbarState({
            message: "Successfully joined the mailing list!",
            success: true,
            open: true,
          });
        },
        (reason: any) => {
          setSnackbarState({
            message: "Could not join mailing list. Please try again later. ",
            success: false,
            open: true,
          });
        }
      );
    } else {
      setSnackbarState({
        message: "Invalid email address. Please check again.",
        success: false,
        open: true,
      });
    }
  }

  return (
    <>
      <input
        type="email"
        placeholder="Join our mailing list"
        className="w-[300px] h-[45px] pl-4 rounded-l-md placeholder-gray-500 text-gray-600 bg-white border-black border-[1px]"
        onChange={(e: any) => setEmail(e.target.value)}
      />
      <button
        className="bg-accentblue text-white px-4 rounded-r-md h-[45px]"
        onClick={onSubscribeClicked}
      >
        Subscribe
      </button>
    </>
  );
}
