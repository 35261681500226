import { Route, Routes } from "react-router";
import About from "../pages/About";
import CaseLaw from "../pages/CaseLaw";
import ContractLaw from "../pages/ContractLaw";
import CorpFin from "../pages/CorpFin";
import LandingPage from "../pages/Landing";
import LegalBench from "../pages/LegalBench";
import TaxEval from "../pages/TaxEval";
import VLair from "../pages/VLair";

export function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="legalbench" element={<LegalBench />} />
      <Route path="contractlaw" element={<ContractLaw />} />
      <Route path="taxeval" element={<TaxEval />} />
      <Route path="corpfin" element={<CorpFin />} />
      <Route path="caselaw" element={<CaseLaw />} />
      <Route path="vlair" element={<VLair />} />
      <Route path="about" element={<About />} />
    </Routes>
  );
}
